import React from 'react';
import { Grid, Hidden } from '@material-ui/core';
import logo from './img/Logo.svg';
import './Login.scss';
import LoginForm from '../LoginForm/LoginForm';
import { authenticationService } from '../../_api/ApiClient';
import { AppRoutes } from '../../_helpers/routes';
import { useHistory } from 'react-router-dom';

const Login = React.memo(() => {
  const history = useHistory();
  if (authenticationService.currentUserValue) {
    history.push(AppRoutes.Cabinet);
  }
  return (
    <div id="login" className="main-container login">
      <Grid
        container
        alignItems="center"
        justify="center"
        className="login__grid"
      >
        <Grid item xs={12} md={6}>
          <Hidden mdDown>
            <img height="570px" width="650px" src={logo} alt="" />
          </Hidden>
        </Grid>
        <Grid item xs={12} lg={6}>
          <LoginForm />
        </Grid>
      </Grid>
    </div>
  );
});

export default Login;

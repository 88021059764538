import React from 'react';
import { Tooltip, Box, Button, makeStyles, Typography, ThemeProvider, Link, Hidden } from '@material-ui/core';
import Logo from './img/Logo.svg';
import { baseUrl, preorderingUrl } from '../../_helpers/links';
import theme from '../../_helpers/MuiTheme';
import { useState } from 'react';
import { authenticationService } from '../../_api/ApiClient';
import { useEffect } from 'react';
import { Suspense } from 'react';
import UserDataDialog from './UserDataDialog';
import { ReactComponent as BasketIcon } from './img/basket.svg';

const useStyles = makeStyles((theme) => ({
  logo: {
    padding: '20px 0',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.down('xs')]: {
      '& img': {
        height: '30px',
      },
    },
  },
  getStyledLink: {
    ...getLinkStyles('#00539F', '#FFF'),
    padding: '10px 16px',
    boxShadow: '0px 6px 14px 0px rgba(0, 84, 158, 0.2)',
    [theme.breakpoints.down('sm')]: {
      margin: 4,
    }
  },
  cabinetIcon: {
    marginRight: '6px',
  },
  basketButton: {
    padding: '20px',
    [theme.breakpoints.up('md')]: {
      marginRight: '20px',
    },
  },
  loginText: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '21px',
  },
}));

const getLinkStyles = (backgroundColor, color) => ({
  padding: '5px 16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '150%',
  color: color,
  textTransform: 'initial',
  backgroundColor: backgroundColor,
  transition: 'background-color 0.4s ease',
  '&:hover': {
    backgroundColor: '#00BE6E',
  },
});

const HeaderMid = () => {
  const classes = useStyles();

  const [logged, setlogged] = useState(null);

  useEffect(() => {
    let subscribe = authenticationService.currentUser.subscribe((token) => {
      setlogged(token);
    });
    return () => {
      subscribe.unsubscribe();
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box display="flex" alignItems="center" justifyContent='space-between'>
        <Box className={classes.logo}>
          <Button component={Link} href={baseUrl} >
            <img src={Logo} alt="Хеликс" />
          </Button>
        </Box>
        <Box display="flex" alignItems="center">
          {logged && (
            <Suspense fallback={null}>
              <UserDataDialog />
            </Suspense>
          )}
          <Tooltip title="Создать заказ">
            <Button href={preorderingUrl} padding={20} className={classes.getStyledLink}>
              <Box gridGap={4} display="flex" alignItems="center">
                <Hidden smDown>
                  <Box>
                    <BasketIcon width={24} height={24} color="white" />
                  </Box>
                </Hidden>
                <Typography className={classes.loginText}>
                  Создать заказ
                </Typography>
              </Box>
            </Button>
          </Tooltip>

        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default HeaderMid;

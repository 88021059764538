class Storage {
  static saveItem = (name, value) => {
    localStorage.setItem(name, JSON.stringify(value));
  };

  static getItem = (item) => JSON.parse(localStorage.getItem(item));

  static save = (name, value) => {
    localStorage.setItem(name, value);
  };

  static get = (item) => localStorage.getItem(item);

  static removeItem = (item) => localStorage.removeItem(item);

  static isLogged = () => {
    const tokenData = Storage.getItem('tokenData');
    if (!tokenData) return false;
    return !(Date.now() >= tokenData.expiresOn * 1000);
  };

  static removeAll = () => {
    localStorage.removeItem('tokenData');
    localStorage.removeItem('profileData');
    localStorage.removeItem('scrollPosition');
  };
}

export default Storage;

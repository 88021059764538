import React, { useState } from 'react';
import { ClickAwayListener, IconButton, Tooltip } from '@material-ui/core';
import InfoIcon from '../img/info.svg';
import PropTypes from 'prop-types';

const ClickTooltip = ({ title, name, iconButtonProps }) => {
  const [openTooltip, setOpenTooltip] = useState(false);

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleOpenTooltip = () => {
    setOpenTooltip(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        title={title}
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={openTooltip}
        disableFocusListener
        disableHoverListener
        disableTouchListener
      >
        <IconButton
          name={name}
          onClick={handleOpenTooltip}
          {...iconButtonProps}
        >
          <img width="24px" height="24px" src={InfoIcon} alt="" />
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  );
};

ClickTooltip.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ClickTooltip;

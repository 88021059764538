import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import clsx from 'clsx';
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';
import { Grid, TextField } from '@material-ui/core';
import styles from './BaseTextField.module.scss';
import ErrorIcon from './img/attention.svg';

const BaseTextField = ({
  label,
  onChange,
  value,
  icon,
  errors,
  name,
  email,
  ...props
}) => {
  const error = errors !== undefined && errors !== false;
  return (
    <>
      <div className={styles.textFieldWrapper}>
        <Grid
          container
          alignItems="center"
          className={clsx(styles.gridRoot, {
            [styles.gridError]: error,
          })}
        >
          <Grid item xs={2} className={styles.gridIcon}>
            {icon}
          </Grid>
          <Grid item xs={9}>
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              error={error}
              fullWidth
              id={`${name}Id`}
              className=""
              variant="filled"
              InputProps={{
                disableUnderline: true,
                classes: { root: styles.root, input: styles.input },
              }}
              InputLabelProps={{
                classes: {
                  root: styles.label,
                  shrink: styles.labelRoot,
                  formControl: styles.labelFormControl,
                  error: styles.labelError,
                },
                'aria-label': label,
              }}
              label={label}
              onChange={onChange}
              value={value}
              aria-invalid={error ? 'true' : 'false'}
              name={name}
            />
          </Grid>
          <Grid item xs={1}>
            {error && (
              <img
                width="24px"
                height="24px"
                src={ErrorIcon}
                alt=""
                loading="lazy"
                className={styles.errorIcon}
              />
            )}
          </Grid>
        </Grid>
        {error && (
          <div className={email ? 'dialog-email__form-helper-wrap' : undefined}>
            <span role="alert" className={styles.formHelperText}>
              {errors.message}
            </span>
          </div>
        )}
      </div>
    </>
  );
};

BaseTextField.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  icon: PropTypes.node.isRequired,
  email: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

BaseTextField.defaultProps = {
  errors: false,
  email: false,
};

export default BaseTextField;

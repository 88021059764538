const regexpHelper = {
  email:
    /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\]\\.,;:\s@\\"]+\.)+[^<>()[\]\\.,;:\s@\\"]{2,})/i,
  phone: /^(\+375)(\d{2})(\d{3})(\d{2})(\d{2})$/,
};

export default regexpHelper;

export const removeSpaces = (value) => {
  return value.replace(/\s/g, '');
};
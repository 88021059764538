import { createSlice } from '@reduxjs/toolkit';
import { castDraft } from 'immer';

const initialState = {
  mainAccounts: [],
  componentAccounts: [],
  currentPersonId: null,
  isMain: false,
};

const personReducer = createSlice({
  name: 'person',
  initialState,
  reducers: {
    AddAccounts(state, action) {
      if (action.payload.mainAccounts != null) {
        state.mainAccounts = action.payload.mainAccounts;
        if (action.payload.mainAccounts?.length > 0) {
          state.currentPersonId = action.payload.mainAccounts[0].groupId;
          state.isMain = true;
        }
      }
      if (action.payload.componentAccounts != null) {
        state.componentAccounts = action.payload.componentAccounts;
      }
    },
    setComponentAccount(state, action) {
      state.componentAccounts = action.payload;
      state.currentPersonId = state.mainAccounts[0].groupId;
    },
    setCurrentPerson(state, action) {
      state.currentPersonId = action.payload;
      const persons = castDraft(state.mainAccounts);
      state.isMain = Boolean(
        persons.find((item) => item.groupId === action.payload)
      );
    },
    deleteAccountById(state, action) {
      const id = action.payload;

      const persons = castDraft(state.componentAccounts);

      const index = persons.findIndex((item) => item.groupId === id);
      state.currentPersonId = state.mainAccounts[0].groupId;
      state.componentAccounts = [
        ...persons.slice(0, index),
        ...persons.slice(index + 1),
      ];
    },
  },
});

export const {
  AddAccounts,
  setCurrentPerson,
  deleteAccountById,
  setComponentAccount,
} = personReducer.actions;
export default personReducer.reducer;

import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import clsx from 'clsx';
import './LoginTabs.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { AppRoutes } from '../../_helpers/routes';

const LoginTabs = ({ state, setState }) => {
  const history = useHistory();
  const location = useLocation();
  const handleClick = (event) => {
    const { name } = event.target;
    switch (name) {
      case 'cabinet': {
        return history.push(AppRoutes.Login);
      }
      case 'code': {
        return history.push(AppRoutes.Code);
      }
      default: {
        return null;
      }
    }
  };

  return (
    <div className="login__tabs tabs-login">
      <button
        type="button"
        value={state.cabinet}
        name="cabinet"
        className={clsx('tabs__cabinet tabs__btn', {
          'tabs__btn--active':
            location.pathname === AppRoutes.Login ||
            location.pathname === AppRoutes.ConfirmLogin,
        })}
        onClick={handleClick}
      >
        Войти в<span className="tabs__sm-block" />
        &nbsp;личный кабинет
      </button>
      <button
        type="button"
        value={state.code}
        name="code"
        className={clsx('tabs__cabinet tabs__btn', {
          'tabs__btn--active': location.pathname === AppRoutes.Code,
        })}
        onClick={handleClick}
      >
        Посмотреть&nbsp;
        <span className="tabs__sm-block" />
        результат по веб-коду
      </button>
    </div>
  );
};

LoginTabs.propTypes = {
  state: PropTypes.shape({ cabinet: PropTypes.bool, code: PropTypes.bool })
    .isRequired,
  setState: PropTypes.func.isRequired,
};

export default LoginTabs;

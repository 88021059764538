import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { authenticationService } from '../_api/ApiClient';
import { useEffect, useState } from 'react';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [logged, setLogged] = useState(authenticationService.currentUserValue);
  useEffect(() => {
    let subscribe = authenticationService.currentUser.subscribe((token) => {
      setLogged(token);
    });
    return () => {
      subscribe.unsubscribe();
    };
  }, []);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!logged) {
          return <Redirect to={'/login'} />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.object.isRequired,
};

export default PrivateRoute;

import React from 'react';
import './Spinner.scss';

const Spinner = () => (
  <div className="spinner-container">
    <div className="loader-four">
      <div className="loader-four__preloader" />
    </div>
  </div>
);

export default Spinner;

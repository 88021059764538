import React, { useState } from 'react';
import {
  BottomNavigationAction,
  BottomNavigation,
  Button,
  useMediaQuery,
  Link,
  makeStyles,
  Box,
  Typography,
  IconButton,
} from '@material-ui/core';
import { ReactComponent as MenuIcon } from './img/menu_icon.svg';
import { ReactComponent as NoteIcon } from './img/Note.svg';
import { ReactComponent as AmbulanceIcon } from './img/reduction.svg';
import { ReactComponent as ReductionIcon } from './img/Ambulance.svg';
import { footerLinks } from '../../_helpers/links';
import MobileMenu from '../Header/MobileMenu';
import theme from '../../_helpers/MuiTheme';
import LinkItem from './LinkItem';

const useStyles = makeStyles((theme) => ({
  mobileMenuBtn: {
    backgroundColor: '#00BE6E',
    borderRadius: 0,
    width: "60px",
    '&:hover': {
      backgroundColor: '#007a47',
    },
  },
  mobileBtn: {},
  root: {
    width: '100%',
    overflow: 'hidden',
    height: '70px',
    position: 'fixed',
    bottom: 0,
    left: 0,
    backgroundColor: '#00539F',
  },
  container: {
    zIndex: '999',
  },
}));

const MobileBottomNavigation = () => {
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  const [open, setopen] = useState(false);

  const toggleDrawer = () => {
    setopen(!open);
  };

  return (
    <Box className={classes.container}>
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
        className={classes.root}
      >
        <Box
          flexGrow={1}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-around"
        >
          <LinkItem
            icon={NoteIcon}
            label={footerLinks.results.name}
            href={footerLinks.results.link}
          />
          <LinkItem
            icon={AmbulanceIcon}
            label={footerLinks.discounts.name}
            href={footerLinks.discounts.link}
          />
          <LinkItem
            icon={ReductionIcon}
            label={footerLinks.hometest.name}
            href={footerLinks.hometest.link}
          />
        </Box>
        <IconButton className={classes.mobileMenuBtn} onClick={toggleDrawer}>
          <MenuIcon />
        </IconButton>
      </BottomNavigation>

      <Box id="mobile-menu">
        <MobileMenu toggleDrawer={toggleDrawer} open={open} />
      </Box>
    </Box>
  );
};

export default MobileBottomNavigation;

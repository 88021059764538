import { createSlice } from '@reduxjs/toolkit';
import deliveryDialogState from '../_const/deliveryDialogState';

const initialState = {
  phoneNumber: '',
  checkbox: false,
  state: deliveryDialogState.Rules,
  isNeedToCallToSendSms: false,
  error: null,
  familyAccount: {
    phone: '',
    barcode: '',
  },
};

const deliveryReducer = createSlice({
  name: 'delivery',
  initialState,
  reducers: {
    setState(state, action) {
      state.state = action.payload;
    },
    setStateWithData(state, action) {
      state.phoneNumber = action.payload.phone;
      state.checkbox = action.payload.checkbox;
      state.state = action.payload.state;
      state.isNeedToCallToSendSms = action.payload.isNeedToCallToSendSms;
    },
    changePhoneNum(state, action) {
      state.phoneNumber = '';
      state.state = deliveryDialogState.NoStatus;
    },
    setError(state, action) {
      state.state = deliveryDialogState.Error;
      state.error = action.payload;
    },
    setStateForFamilyAccount(state, action) {
      state.state = deliveryDialogState.AddResultForFamilyAccount;
      state.familyAccount = {
        phone: action.payload.phone,
        barcode: action.payload.barcode,
      };
    },
  },
});

export const { setState, changePhoneNum, setStateWithData, setError, setStateForFamilyAccount } =
  deliveryReducer.actions;
export default deliveryReducer.reducer;

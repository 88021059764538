import { Box } from '@material-ui/core';
import React, { Suspense, useState } from 'react';
import { Route } from 'react-router-dom';
import { AppRoutes } from '../../_helpers/routes';
import DeliveryOutlinedButton from '../Delivery/DeliveryButton';
import CabinetForm from './CabinetForm';
import './LoginForm.scss';
import LoginTabs from './LoginTabs';

const ConfirmLogin = React.lazy(() => import('./ConfirmLogin'));
const GetResultsByCode = React.lazy(() => import('./GetResultsByCodeForm'));

const LoginForm = () => {
  const [tabs, setTabs] = useState({
    cabinet: true,
    code: false,
  });
  return (
    <div className="login-form">
      <LoginTabs state={tabs} setState={setTabs} />
      <div className="login__wrap">
        <Suspense>
          <Route exact path={AppRoutes.Login} component={CabinetForm} />
          <Route exact path={AppRoutes.ConfirmLogin} component={ConfirmLogin} />
          <Route exact path={AppRoutes.Code} component={GetResultsByCode} />
        </Suspense>
      </div>
      <Box pt={{ xs: 3, sm: 4 }} textAlign="center">
        <DeliveryOutlinedButton />
      </Box>
    </div>
  );
};

export default React.memo(LoginForm);

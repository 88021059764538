import { Box, makeStyles,  Link , useMediaQuery} from '@material-ui/core';
import HeaderTopNavbar from '../DropdownMenu/HeaderTopNavbar';
import theme from '../../_helpers/MuiTheme';
import { ReactComponent as PhoneIcon } from './img/phone_icon.svg';
const useStyles = makeStyles({

  rightContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
    boxSizing: 'border-box',
      overflow: 'auto',
      overflowX: 'auto', 
      background: 'linear-gradient(270deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%)',
      '&::-webkit-scrollbar': {
        display: 'none', 
      },
    },
  },
  
  phoneNumber: {
    fontFamily: ['Lato', 'sans-serif'],
    padding: '10px',
    color: '#00BE6E',
    fontSize: '33.6px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '27.3px',
    textTransform: 'uppercase',
  },
  phoneIcon: {
    marginRight: theme.spacing(1),
  },

});
const HeaderTop = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.up('md'));


  return (
    <header>
      <div  className={classes.rightContainer} display="flex" >
        <HeaderTopNavbar />
        {isMobile && (
        <Box display="flex" alignItems="center" className={classes.rightContainer}>
            <PhoneIcon className={classes.phoneIcon} />
            <Link href="tel:7766" className={classes.phoneNumber} aria-label="Номер гарячей линии">
              7766
            </Link>
        </Box>
        )}
      </div>
    </header>
  );
};

export default HeaderTop;

import React, { useState } from 'react';
import { Box, Checkbox, Typography } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import clsx from 'clsx';
import ReactInputMask from 'react-input-mask';
import { useLocation } from 'react-router-dom';
import PhoneIcon from './img/phone.svg';
import BaseTextField from '../../_helpers/BaseTextField/BaseTextField';
import { AppRoutes } from '../../_helpers/routes';
import RecaptchaBtn from './RecaptchaBtn';
import ApiClient from '../../_api/ApiClient';
import Storage from '../../_helpers/Storage';
import ClickTooltip from './ClickTooltip/ClickTooltip';
import validationHelper from '../../_helpers/validationHelper';
import StyledDatePicker from '../Common/StyledDatePicker';
import { ReactComponent as CakeIcon } from '../_icons/cake.svg';
import { fetchStatus } from '../../_const/fetchStatus';
import ErrorDialog from './ErrorDialog/ErrorDialog';
import { Redirect } from 'react-router-dom';
import LoadingButton from '../Common/LoadingButton';
import errorHelper from '../../_helpers/errorHelper';
import { clientPolicy } from '../../_helpers/links';

const CabinetForm = () => {
  const { handleSubmit, control, errors, setValue, setError } = useForm({
    mode: 'onChange',
  });
  const location = useLocation();

  const [message, setMessage] = useState('');
  const [status, setStatus] = useState(fetchStatus.IDLE);

  const loading = status === fetchStatus.PENDING;
  const error = status === fetchStatus.REJECTED;
  const success = status === fetchStatus.FULFILLED;

  const grecaptchaObject = window.grecaptcha;

  const GetAccountAsync = async ({ phoneNumber, dateOfBirth, reCaptcha }) => {
    try {
      setStatus(fetchStatus.PENDING);
      const formatedPhone = phoneNumber.replace(/\s/g, '');
      const formatedDate = (await import('date-fns/esm')).format(
        dateOfBirth,
        'yyyyMMdd'
      );
      const response = await ApiClient.GetAccount(
        formatedPhone,
        formatedDate,
        reCaptcha
      )
        .then((res) => {
          if (res) {
            if (res.isSuccess) {
              Storage.saveItem('temp_accountIdentificator', {
                accountIdentificator: res.accountIdentificator,
                person: {
                  phoneNumber: formatedPhone,
                  dateOfBirth: formatedDate,
                },
              });
              setStatus(fetchStatus.FULFILLED);
              return true;
            }
            throw new Error(res.statusMessage);
          }
          throw new Error(errorHelper.common);
        })
        .catch((e) => {
          setMessage(e.message);
          setStatus(fetchStatus.REJECTED);
          return false;
        })
        .finally((val) => {
          if (grecaptchaObject.reset) {
            grecaptchaObject.reset();
            setValue('reCaptcha', '', {
              shouldValidate: true,
              shouldDirty: true,
            });
          }
          return val;
        });
      return response;
    } catch (e) {
      setMessage(e.message);
      setStatus(fetchStatus.REJECTED);
      if (grecaptchaObject.reset) {
        grecaptchaObject.reset();
        setValue('reCaptcha', '', {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
      return false;
    }
  };

  const onSubmit = async (data) => {
    return GetAccountAsync(data);
  };

  const handleClose = () => {
    setStatus(fetchStatus.IDLE);
  };

  const { from } = location.state || {
    from: { pathname: AppRoutes.ConfirmLogin },
  };

  return (
    <>
      {success && <Redirect to={from} />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mb={3}>
          <Typography>
            Введите данные клиента, указанные при оформлении заказа
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" mb={3} mr={{ xs: -1, sm: 0 }}>
          <Controller
            name="phoneNumber"
            control={control}
            rules={{
              required: validationHelper.required,
              validate: validationHelper.phoneNumber,
            }}
            defaultValue=""
            render={({ onChange, value }) => (
              <ReactInputMask
                mask="+375 99 999 99 99"
                maskChar=""
                value={value}
                onChange={onChange}
              >
                {(inputProps) => (
                  <BaseTextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...inputProps}
                    label="Номер телефона"
                    name="phone"
                    icon={
                      <img
                        width="24px"
                        height="24px"
                        src={PhoneIcon}
                        alt=""
                        loading="lazy"
                        className="login__icon"
                      />
                    }
                    onChange={onChange}
                    value={value}
                    errors={errors.phoneNumber}
                  />
                )}
              </ReactInputMask>
            )}
          />
          <ClickTooltip
            title="Укажите номер телефона, который Вы оставляли при оформлении заказа"
            name="Подсказка"
          />
        </Box>
        <Box display="flex" alignItems="center" mb={3} mr={{ xs: -1, sm: 0 }}>
          <Controller
            name="dateOfBirth"
            control={control}
            rules={{
              required: validationHelper.required,
              validate: validationHelper.date,
            }}
            defaultValue={null}
            render={({ onChange, value }) => (
              <StyledDatePicker
                onFocus={() => {
                  return value === null
                    ? setError('dateOfBirth', {
                        types: 'validate',
                        message: 'Формат ДД.ММ.ГГГГ',
                      })
                    : undefined;
                }}
                label="Дата рождения"
                name="dateOfBirth"
                startAdornment={<CakeIcon />}
                onChange={onChange}
                value={value}
                error={errors.dateOfBirth}
              />
            )}
          />
          <ClickTooltip
            title="Укажите дату рождения, если заказ оформлялся для ребенка, укажите дату рождения ребенка"
            name="Подсказка"
          />
        </Box>
        <Box mb={{ xs: 2, md: 3 }} className="login__checkbox checkbox-login">
          <Controller
            name="checkbox2"
            control={control}
            rules={{ required: process.env.NODE_ENV === 'production' && true }}
            defaultValue={false}
            render={({ value, onChange, ref }) => (
              <Checkbox
                className={clsx('checkbox', {
                  'checkbox--error': errors.checkbox2 !== undefined,
                })}
                checked={value}
                onChange={(e) => onChange(e.target.checked)}
                inputRef={ref}
              />
            )}
          />

          <span className="checkbox__label">
          Предварительно ознакомившись с&nbsp;
            <a
              href={clientPolicy}
              target="_blank"
              className="checkbox__link"
              rel="noreferrer"
            >
              информацией,&nbsp;
            </a>
          я даю свое согласие на обработку моих персональных данных.
          </span>
        </Box>
        <div className="login__field login__field--capthca">
          <Controller
            name="reCaptcha"
            control={control}
            defaultValue=""
            rules={{ required: process.env.NODE_ENV === 'production' && true }}
            render={({ onChange }) => (
              <RecaptchaBtn
                onChange={onChange}
                error={errors.reCaptcha}
                grecaptcha={grecaptchaObject}
              />
            )}
          />
        </div>
        <LoadingButton
          type="submit"
          fullWidth
          variant="contained"
          loading={loading}
        >
          Получить код проверки по СМС
        </LoadingButton>
      </form>
      <ErrorDialog
        open={error}
        handleClose={handleClose}
        errorMessage={message}
      />
    </>
  );
};

export default CabinetForm;

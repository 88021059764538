import {
  combineReducers,
  configureStore,
  createAction,
} from '@reduxjs/toolkit';
import throttle from '../_helpers/functions/trottle';
import { getStorage, setStorage } from '../_helpers/localStorage';
import deliveryOrderReducer from './deliveryOrderReducer';
import deliveryReducer from './deliveryReducer';
import orderReducer from './orderReducer';
import personReducer from './personReducer.jsx';

const logout = createAction('user/logout');

const combineReducer = combineReducers({
  person: personReducer,
  order: orderReducer,
  delivery: deliveryReducer,
  deliveryOrder: deliveryOrderReducer,
});

const rootReducer = (state, action) => {
  if (action.type === logout.type) {
    state = undefined;
  }
  return combineReducer(state, action);
};

const state = getStorage('state_results');

const store = configureStore({
  reducer: rootReducer,
  preloadedState: state || undefined,
  devTools: process.env.NODE_ENV === 'production' ? false : true,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

store.subscribe(
  throttle(() => {
    setStorage('state_results', {
      deliveryOrder: store.getState().deliveryOrder,
      person: store.getState().person,
    });
  }, 1000)
);

export { logout };
export default store;

import { Dialog, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import CloseIcon from '../img/close.svg';
import './ErrorDialog.scss';


const ErrorDialog = ({ open, handleClose, errorMessage }) => (
  <Dialog
    aria-labelledby="user-not-found"
    open={open}
    onClose={handleClose}
    className="dialog-error"
  >
    <div className="dialog-error__close">
      <IconButton onClick={handleClose}>
        <img src={CloseIcon} alt="Закрыть" loading="lazy" />
      </IconButton>
    </div>
    <div id="user-not-found" className="dialog-error__title">
      <span dangerouslySetInnerHTML={{ __html: errorMessage }} ></span>
    </div>
  </Dialog>
);

ErrorDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
};

export default ErrorDialog;

import { createTheme } from '@material-ui/core';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00539F',
      dark: '#002955',
    },
    text: {
      main: '#2E3B52',
    },
    error: {
      main: '#B00020',
    },
    secondary: {
      main: '#00BE6E',
    },
    greyLightPlus: {
      main: '#E7E7E7',
    },
    grey: {
      main: '#727272',
      light: '#BCBCBC',
    },
    title: {
      main: '#758AB0',
    },
    blueLight: {
      main: '#CCD6E3',
      light: '#F4F6F9'
    },
    background: {
      main: '#F4F4F4'
    }
  },
  typography: {
    fontFamily: ['Lato', 'Arial'],
  },
});

theme.typography.h4 = {
  fontSize: '1.5rem',
  lineHeight: '29px',
  fontWeight: 700,
  letterSpacing: '0.15px',
  '@media (min-width:600px)': {
    fontSize: '2rem',
    lineHeight: '38px',
  },
};

theme.typography.h5 = {
  fontSize: '1.13rem',
  fontWeight: 700,
  lineHeight: '29px',
  letterSpacing: '0.15px',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
};

theme.typography.h6 = {
  fontSize: '1rem',
  fontWeight: 500,
  lineHeight: '22px',
  '@media (min-width:600px)': {
    fontSize: '1.125rem',
  },
};

theme.typography.body1 = {
  fontSize: '1rem',
  fontWeight: 400,
  lineHeight: '19px',
  '@media (min-width:600px)': {
    fontSize: '0.85rem',
    lineHeight: '17px',
  },
};

export default theme;

import { isAfter, isBefore } from 'date-fns';
import errorHelper from './errorHelper';
import regexpHelper from './regexpHelper';

const phoneNumber = (value) => {
  if (value && regexpHelper.phone.test(value.replace(/\s/gm, ''))) {
    return true;
  }
  return errorHelper.phone;
};

const date = (value) => {
  const date = new Date(value);
  if (date instanceof Date && !isNaN(date)) {
    if (!isBefore(date, new Date(1900, 0, 1)) && isAfter(date, new Date())) {
      return 'Дата не должна быть позже сегодняшней даты';
    }
    if (isBefore(date, new Date(1900, 0, 1)) && !isAfter(date, new Date())) {
      return 'Дата не должна быть раньше минимальной даты';
    }
    return true;
  }
  return 'Формат ДД.ММ.ГГГГ';
};

const email = (value) => {
  return regexpHelper.email.test(value) || 'Вы ввели некорректный e-mail адрес';
};

const validationHelper = {
  phoneNumber,
  required: 'Это поле обязательно',
  date,
  email,
};

export default validationHelper;

import { createSlice } from '@reduxjs/toolkit';
import { removeStorage } from '../_helpers/localStorage';

const initialState = {
  count: 0,
  saved: false,
};

const deliveryOrderReducer = createSlice({
  name: 'deliveryOrder',
  initialState,
  reducers: {
    addOrder(state) {
      state.count = state.count + 1;
    },
    deleteOrder(state) {
      state.count = state.count - 1;
    },
    setOrderCount(state, action) {
      state.count = action.payload;
    },
    resetOrder(state) {
      state.count = 0;
      state.saved = false;
      removeStorage('basketId');
    },
    setSaved(state) {
      state.saved = true;
    }
  },
});

export const {
  addOrder,
  resetOrder,
  setOrderCount,
  deleteOrder,
  setSaved
} = deliveryOrderReducer.actions;
export default deliveryOrderReducer.reducer;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiClient from '../_api/ApiClient';
import { fetchStatus } from '../_const/fetchStatus';
import errorHelper from '../_helpers/errorHelper';

const initialState = {
  orders: [],
  orderData: null,
  status: fetchStatus.IDLE,
  message: '',
  total: 0,
  error: false,
  pageSize: 10,
  pageNumber: 1,
};

const fetchByOrders = createAsyncThunk(
  'orders/fetch',
  async (fetchForm, thunkApi) => {
    const { pageNumber, pageSize } = fetchForm;
    const id = thunkApi.getState().person?.currentPersonId;

    return ApiClient.GetOrderByPerson(id, pageNumber, pageSize)
      .then((res) => {
        if (res) {
          const { orderCount, statusMessage, orders } = res;
          if (res.isSuccess) {
            return {
              total: orderCount,
              orders,
              message: statusMessage,
            };
          }
          throw new Error(statusMessage);
        }
        throw new Error(errorHelper.common);
      })
      .catch((e) => {
        return thunkApi.rejectWithValue({
          message: e.message,
        });
      });
  }
);

const orderReducer = createSlice({
  initialState,
  name: 'orders',
  reducers: {
    removeOrders(state) {
      state.orders = [];
      state.status = fetchStatus.IDLE;
      state.pageNumber = 1;
    },
    setPageNumber(state, action) {
      state.pageNumber = action.payload;
    },
  },
  extraReducers: {
    [fetchByOrders.pending]: (state) => {
      state.status = fetchStatus.PENDING;
    },
    [fetchByOrders.fulfilled]: (state, action) => {
      state.status = fetchStatus.FULFILLED;
      state.message = action.payload.message || '';
      state.orders = state.orders.concat(action.payload.orders);
      state.total = action.payload.total;
    },
    [fetchByOrders.rejected]: (state, action) => {
      state.status = fetchStatus.REJECTED;
      state.message = action.payload.message;
      state.orders = [];
      state.total = 0;
    },
  },
});

export { fetchByOrders };
export const { removeOrders, setPageNumber } = orderReducer.actions;
export default orderReducer.reducer;

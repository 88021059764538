import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
// eslint-disable-next-line import/no-extraneous-dependencies
import clsx from 'clsx';

const RecaptchaBtn = ({ onChange, error, grecaptcha, gref }) => {
  const capthcaError = error !== undefined && error;
  return (
    <ReCAPTCHA
      ref={gref}
      sitekey={process.env.REACT_APP_SITE_KEY}
      onChange={onChange}
      className={clsx('m-x-auto login__captcha', {
        recaptcha__error: capthcaError,
      })}
      grecaptcha={grecaptcha}
    />
  );
};

RecaptchaBtn.propTypes = {
  onChange: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.shape({
      message: PropTypes.string,
      ref: PropTypes.shape({
        focus: PropTypes.func,
        name: PropTypes.string,
      }),
      type: PropTypes.string,
    }),
    PropTypes.bool,
  ]),
};

RecaptchaBtn.defaultProps = {
  error: false,
};

export default RecaptchaBtn;

export const IDLE = 'idle';
export const PENDING = 'pending';
export const FULFILLED = 'fulfilled';
export const REJECTED = 'rejected';

export const fetchStatus = {
  IDLE,
  PENDING,
  FULFILLED,
  REJECTED,
};

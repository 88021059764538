export const orderStatusConst = {
  ok: [2],
  inWork: [3],
  error: [0, 4],
  new: [1],
  black: [5],
};
export const orders = new Map([
  [0, 'Ошибка'],
  [1, 'Новый заказ'],
  [2, 'Готов'],
  [3, 'В работе'],
  [4, 'Отменен'],
  [5, 'Непроизводственный'],
]);

import React, { Suspense } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { Hidden } from '@material-ui/core';
const FooterAdd = React.lazy(() => import('../FooterAdd/FooterAdd'));

const PageWrapper = ({ children }) => {
  return (
    <>
      <div className="wrapper">
        <Header />
        {children}
      </div>
      <Footer />
    </>
  );
};

export default PageWrapper;

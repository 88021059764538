export function removeStorage(name) {
  try {
    window.localStorage.removeItem(name);
    window.localStorage.removeItem(`${name}_expiresIn`);
  } catch (e) {
    return false;
  }
  return true;
}

export function getStorage(key) {
  const now = Date.now();
  let expiresIn = window.localStorage.getItem(`${key}_expiresIn`);
  if (expiresIn === undefined || expiresIn === null) {
    expiresIn = 0;
  }

  if (expiresIn < now) {
    removeStorage(key);
    return null;
  }
  try {
    const value = window.localStorage.getItem(key);
    return JSON.parse(value);
  } catch (e) {
    return null;
  }
}

// expires в секундах

export function setStorage(key, value, expires) {
  if (expires === undefined || expires === null) {
    // eslint-disable-next-line no-param-reassign
    expires = 60 * 20; // 20 min
  } else {
    // eslint-disable-next-line no-param-reassign
    expires = Math.abs(expires); // make sure it positive
  }

  const now = Date.now();
  const schedule = now + expires * 1000;
  try {
    const serializedState = JSON.stringify(value);
    window.localStorage.setItem(key, serializedState);
    window.localStorage.setItem(`${key}_expiresIn`, schedule);
  } catch (e) {
    return false;
  }
  return true;
}

export const AppRoutes = {
  Login: '/login',
  Cabinet: '/cabinet',
  DynamicResult: '/cabinet/dynamic-result',
  PersonDynamicResult: '/cabinet/person-dynamic-result',
  ResultsByCode: '/results-by-code',
  ConfirmLogin: '/login/confirm',
  Error404: '/error404',
  Code: '/login/code',
  ResultById: '/result',
  Delivery: '/delivery',
  DeliverySuccess: '/delivery-success',
  DeliveryFail: '/delivery-fail',
};

import Storage from '../_helpers/Storage';
import { BehaviorSubject } from 'rxjs';
import userLogout from '../_helpers/functions/logout';

const currentUserSubject = new BehaviorSubject(Storage.getItem('tokenData'));

function logout() {
  Storage.removeAll();
  currentUserSubject.next(null);
}

function login(user) {
  Storage.saveItem('tokenData', user);
  currentUserSubject.next(user);
}

export const authenticationService = {
  login,
  logout,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
};

class ApiClient {
  static Headers = async (options) => {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    if (options.authorization) {
      let tokenData = Storage.getItem('tokenData');

      if (!tokenData) throw Error('Unauthorized');

      if (Date.now() >= tokenData.expiresOn * 1000) {
        // истек срок жизни токена
        userLogout();
        return headers;
      }

      headers.Authorization = `Bearer ${tokenData.accessToken}`;
    }

    if (options.reCaptchaToken) {
      headers.Recaptcha = options.reCaptchaToken;
    }

    return headers;
  };

  static Response = async (response) => {
    if (response.status === 200) {
      if (response?.headers?.get('Content-Type') === 'application/pdf') {
        // console.log(response);
        return response.blob();
      }

      const text = await response.text();

      if (text.length === 0) return {};
      return JSON.parse(text);
    }

    if (response.status === 204) {
      return undefined;
    }

    if (response.status === 401) {
      return userLogout();
    }

    if (response.status === 500) {
      const json = await response.json();
      throw Error(json.Message);
    }

    // get error from api
    if (response.status === 501) {
      const text = await response.text();
      throw Error(text);
    }

    throw Error(response.statusText);
  };

  static post = async (url, body = {}, options = { authorization: true }) => {
    const version = 'v1.0';
    const fullUrl = process.env.REACT_APP_BASE_URL + version + url;
    // console.log(fullUrl);

    const headers = await ApiClient.Headers(options);
    const response = await fetch(fullUrl, {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    });
    return ApiClient.Response(response);
  };

  static get = async (url, options = { authorization: true }) => {
    const version = 'v1.0';
    const fullUrl = process.env.REACT_APP_BASE_URL + version + url;
    // console.log(fullUrl); // eslint-disable-line no-console
    const params = options.params ? `?${options.params}` : '';

    const headers = await ApiClient.Headers(options);
    const response = await fetch(fullUrl + params, { headers });
    return ApiClient.Response(response);
  };

  static _delete = async (url, options = { authorization: true }) => {
    const version = 'v1.0';
    const fullUrl = process.env.REACT_APP_BASE_URL + version + url;

    const params = options.params ? `?${options.params}` : '';
    const headers = await ApiClient.Headers(options);
    const response = await fetch(fullUrl + params, {
      headers,
      method: 'DELETE',
    });
    return ApiClient.Response(response);
  };

  // Account

  static AccountVerification = async (phoneNumber, dateOfBirth) => {
    const params = new URLSearchParams();
    params.append('phoneNumber', phoneNumber);
    params.append('dateOfBirth', dateOfBirth);

    return ApiClient.get('/Account/Verification', {
      authorization: true,
      params,
    });
  };

  static GetAccount = async (phoneNumber, dateOfBirth, reCaptchaToken) => {
    const params = new URLSearchParams();
    params.append('phoneNumber', phoneNumber);
    params.append('dateOfBirth', dateOfBirth);

    return ApiClient.get('/Account/GetAccount', {
      authorization: false,
      params,
      reCaptchaToken,
    });
  };

  static ConfirmAccount = async (phoneNumber, dateOfBirth) => {
    const params = new URLSearchParams();
    params.append('phoneNumber', phoneNumber);
    params.append('dateOfBirth', dateOfBirth);

    return ApiClient.get('/Account/ConfirmAccount', {
      authorization: false,
      params,
    });
  };

  static AddToFamilyAccount = async (verificationCode) =>
    ApiClient.post('/Account/AddToFamilyAccount', { verificationCode });

  static AccountDelete = async (deleteItemId) => {
    const params = new URLSearchParams();
    params.append('componentAccountGroupId', deleteItemId);

    return ApiClient._delete('/Account', {
      authorization: true,
      params,
    });
  };

  static GetAllAccountComponents = async () => {
    return ApiClient.get('/Account/AllAccountComponents');
  };

  static ResendSmsForCandidateToAddComponentAccount = async () =>
    ApiClient.get('/Account/ResendSmsForCandidateToAddComponentAccount');

  // Token

  static Token = async (account, confirmNumber) =>
    ApiClient.post(
      '/Token',
      {
        account,
        confirmNumber: parseInt(confirmNumber, 10),
      },
      { authorization: false }
    );

  // Mail

  static Mail = async ({ firstName, lastName, email, barcode, webcode }) =>
    ApiClient.post('/Mail', {
      firstName,
      lastName,
      email,
      barcode,
      webcode,
    });

  static SendByWebCode = async ({
    firstName,
    lastName,
    email,
    barcode,
    webcode,
  }) =>
    ApiClient.post(
      '/Mail/sendbywebcode',
      {
        firstName,
        lastName,
        email,
        barcode,
        webcode,
      },
      { authorization: false }
    );

  // Order

  static GetOrderByPerson = async (id, pageNumber, pageSize) => {
    const params = new URLSearchParams();
    params.append('accountGroupId', id);
    params.append('pageNumber', pageNumber);
    params.append('pageSize', pageSize);

    return ApiClient.get('/Order', {
      authorization: true,
      params,
    });
  };

  // Result

  static Result = async (orderId, personId) => {
    const params = new URLSearchParams();
    params.append('personId', personId);
    params.append('orderId', orderId);
    return ApiClient.get('/Result', { authorization: true, params });
  };

  static ResultPdf = async (barcode, personId) => {
    const params = new URLSearchParams();
    params.append('barcode', barcode);
    params.append('personId', personId);
    return ApiClient.get('/Result/pdf', { authorization: true, params });
  };

  static OrderStateAndResults = async (webCode, reCaptchaToken) =>
    ApiClient.get(`/Result/OrderStateAndResults?webCode=${webCode}`, {
      authorization: false,
      reCaptchaToken,
    });

  static HashCode = async (id) =>
    ApiClient.get(`/Result/HashCode?id=${id}`, {
      authorization: false,
    });


  //DynamicResult

  
  static GetDynamicResultByPersonAccountId = async (personAccountId) =>
  ApiClient.post('/DynamicResult/GetDynamicResultByPersonAccountId', personAccountId, { authorization: true });


  static GetDynamicResultDetailsByExternalOrderIdAndPersonAccountId = async ({ externalOrderId, personAccountIds }) => {
    const queryString = `?externalOrderId=${encodeURIComponent(externalOrderId)}`;
    const url = `/DynamicResult/GetDynamicResultDetailsByExternalOrderIdAndPersonAccountId${queryString}`;
    
    const payload = Array.isArray(personAccountIds) ? personAccountIds : [personAccountIds];
    
    return ApiClient.post(url, payload, { authorization: true });
  };

  static GetPersonAccountXIndexResult = async (dynamicResultRequestDto) =>
  ApiClient.post('/DynamicResult/GetPersonAccountXIndexResult', dynamicResultRequestDto, { authorization: true });


  // DeliveryOrder

  static GetDeliveryOrder = async (
    { reCaptchaToken, phone, barcode },
    basketId = null
  ) => {
    const params = new URLSearchParams();
    params.append('barcode', barcode);
    params.append('phone', phone);

    if (basketId) {
      params.append('basketId', basketId);
    }
    return ApiClient.get('/DeliveryOrder/Verification', {
      params,
      authorization: false,
      reCaptchaToken: reCaptchaToken,
    });
  };

  static DeliverySendSms = async (basketId) => {
    return ApiClient.post('/DeliveryOrder/SendSms', basketId, {
      authorization: false,
    });
  };

  static DeliveryAddToBasket = async (basketId, verificationCode = null) => {
    const body = { basketId };
    if (verificationCode) {
      body.verificationCode = verificationCode;
    }

    return ApiClient.post('/DeliveryOrder/AddToBasket', body, {
      authorization: false,
    });
  };

  static DeliveryBasketDetails = async (basketId) => {
    const params = new URLSearchParams();
    params.append('basketId', basketId);

    return ApiClient.get('/DeliveryOrder/BasketDetails', {
      authorization: false,
      params,
    });
  };

  static DeliveryOrderDelete = async (basketId, deleteItemId) => {
    const params = new URLSearchParams();
    params.append('basketId', basketId);
    params.append('deleteItemId', deleteItemId);

    return ApiClient._delete('/DeliveryOrder', {
      authorization: false,
      params,
    });
  };

  static DeliverySaveBasket = async (basketId) => {
    return ApiClient.post('/DeliveryOrder/SaveBasket', basketId, {
      authorization: false,
    });
  };

  static DeliveryOrderResult = async (barcode) => {
    const params = new URLSearchParams();
    params.append('barcode', barcode);

    return ApiClient.get('/DeliveryOrder/Result', {
      authorization: false,
      params,
    });
  };

  /**
   *
   * @param {{
   * "recipient": "string",
   *  "phone": "string",
   *  "city": "string",
   *  "street": "string",
   *  "house": "string",
   *  "housing": "string",
   *  "apartment": "string",
   *  "comment": "string",
   *  "basketId": "string"}} formData
   * @returns
   */
  static DeliveryOrderPay = async (formData) => {
    return ApiClient.post('/DeliveryOrder/Pay', formData, {
      authorization: false,
    });
  };

  static DeliveryOrderInitDeliveryAddress = async (basketId) => {
    const params = new URLSearchParams();
    params.append('basketId', basketId);

    return ApiClient.get('/DeliveryOrder/InitDeliveryAddress', {
      authorization: false,
      params,
    });
  };

  static DeliveryVerificationByWebcode = async (
    webcode,
    basketId,
    reCaptchaToken
  ) => {
    const params = new URLSearchParams();
    params.append('webcode', webcode);

    if (basketId) {
      params.append('basketId', basketId);
    }

    return ApiClient.get('/DeliveryOrder/VerificationByWebcode', {
      authorization: false,
      params,
      reCaptchaToken,
    });
  };

  static DeliveryVerificationForFamilyAccount = async (
    barcode,
    phone,
    basketId,
    reCaptchaToken
  ) => {
    const params = new URLSearchParams();
    params.append('barcode', barcode);
    params.append('phone', phone);

    if (basketId) {
      params.append('basketId', basketId);
    }

    return ApiClient.get('/DeliveryOrder/VerificationForFamilyAccount', {
      authorization: true,
      params,
      reCaptchaToken,
    });
  };
}

export default ApiClient;

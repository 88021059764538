import { withStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';

const StyledButton = withStyles(({ palette, breakpoints }) => ({
  root: {},
  text: {
    color: palette.primary.main,
    fontSize: 14,
    lineHeight: '17px',
    textTransform: 'initial',
    justifyContent: (props) => props.justifyContent || 'center',
  },
  contained: {
    minWidth: 192,
    background: palette.primary.main,
    borderRadius: 27,
    height: 48,
    color: '#FFFFFF',
    '&:hover': {
      background: '#002955',
      color: '#FFFFFF',
    },
    '& .MuiButton-label': {
      fontWeight: 400,
      textTransform: 'initial',
      fontSize: 14,
      lineHeight: '17px',
      '@media (min-width:600px)': {
        fontSize: 16,
        lineHeight: '19.2px',
      },
    },
    '&:disabled': {
      backgroundColor: '#BCBCBC',
      color: '#FFFFFF',
    },
  },
  outlined: {
    minWidth: 192,
    border: `2px solid ${palette.primary.main}`,
    height: 48,
    borderRadius: 27,
    color: palette.primary.main,
    '& .MuiButton-label': {
      fontWeight: 400,
      textTransform: 'initial',
    },
    '&:hover': {
      background: palette.secondary.main,
      color: '#FFFFFF',
    },
  },
  outlinedSizeSmall: {
    minWidth: 'auto',
    height: 'auto',

    padding: '8px 16px',
    border: `1px solid ${palette.primary.main}`,
    gap: 8,
    backgroundColor: 'transparent',

    fontSize: 16,
    lineHeight: '19.2px',

    '&:hover': {
      borderColor: palette.primary.dark,
      background: palette.primary.dark,
      color: '#FFFFFF',
    },
    [breakpoints.down('xs')]: {
      fontSize: 14,
      lineHeight: '17.2px',
    },
  },
}))(Button);

export default StyledButton;

import { Box, CircularProgress, Dialog, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import CloseIcon from '../_icons/close.svg';

const DeliveryService = React.lazy(() => import('./DeliveryService'));

const DeliveryDialog = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <Box position="absolute" top={10} right={10}>
        <IconButton onClick={onClose}>
          <img src={CloseIcon} alt="" width="16px" heigth="16px" />
        </IconButton>
      </Box>
      <Box
        px={{ xs: 2, sm: 7, lg: 8 }}
        pt={{ xs: 5, lg: 6 }}
        pb={{ xs: 4, lg: 5 }}
      >
        <Suspense
          fallback={
            <Box p={10} textAlign="center">
              <CircularProgress />
            </Box>
          }
        >
          <DeliveryService closeDialog={onClose} />
        </Suspense>
      </Box>
    </Dialog>
  );
};

DeliveryDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DeliveryDialog;

const deliveryDialogState = {
  NoStatus: 0,
  Error: 1,
  PartialReadyNeedConfirm: 2,
  PartialReadyNeedSmsConfirm: 3,
  ReadyNeedSmsConfirm: 4,
  AddToBasket: 5,
  Canceled: 6,
  Rules: 7,
  WebCodeAdding: 8,
  AddResultForFamilyAccount: 9,
};

export default deliveryDialogState;

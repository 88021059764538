import React from 'react';
import ruLocale from 'date-fns/locale/ru';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { ReactComponent as CalendarIcon } from '../_icons/calendar.svg';
import DateFnsUtils from '@date-io/date-fns';
import './StyledInput.scss';
import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const styles = makeStyles({
  labelRoot: {
    '@media (max-width: 360px)': {
      maxWidth: 160,
    },
  },
  labelFilled: {
    '@media (max-width: 360px)': {
      transform: 'translate(0px, 7px) scale(1) !important',
    },
  },
  labelShrink: {
    '@media (max-width: 360px)': {
      transform: 'translate(0px, 2px) scale(0.75) !important',
    },
  },
  input: {
    '@media (max-width: 360px)': {
      padding: '25px 12px 4px 0px !important',
    },
  },
});

const StyledDatePicker = ({
  invalidDateMessage,
  onChange,
  value,
  label,
  error,
  className,
  icon,
  startAdornment,
  readOnly,
  ...props
}) => {
  const classes = styles();
  const input = (
    <KeyboardDatePicker
      {...props}
      fullWidth
      className={clsx('styled-input', className, {
        'start-adorment': startAdornment,
        'styled-input-error': error,
        'styled-input-read-only': readOnly,
      })}
      inputVariant="filled"
      error={Boolean(error?.message)}
      helperText={error?.message}
      maxDate={new Date()}
      InputProps={{
        disableUnderline: true,
        readOnly: readOnly,
        classes: { input: classes.input },
      }}
      InputLabelProps={{
        classes: {
          root: classes.labelRoot,
          shrink: classes.labelShrink,
          filled: classes.labelFilled,
        },
      }}
      keyboardIcon={<CalendarIcon />}
      onChange={onChange}
      value={value}
      invalidDateMessage={invalidDateMessage}
      variant="dialog"
      format="dd.MM.yyyy"
      name="dateOfBirth"
      label={label}
      KeyboardButtonProps={{
        'aria-label': 'date of birth',
      }}
    />
  );

  if (startAdornment) {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
        <Box
          flexGrow={1}
          display="flex"
          alignItems="center"
          className={clsx('styled-input-grid', className, {
            'styled-input-error': error,
          })}
        >
          <Box flexGrow={0} px={2} margin={0} className="styled-input-icon">
            {startAdornment}
          </Box>
          <Box flexGrow={1}>{input}</Box>
        </Box>
      </MuiPickersUtilsProvider>
    );
  }
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
      {input}
    </MuiPickersUtilsProvider>
  );
};

export default StyledDatePicker;

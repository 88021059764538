import React from 'react';
import useDialog from '../../_helpers/useDialog';
import StyledButton from '../Common/StyledButton';
import DeliveryDialog from '../DeliveryDialog/DeliveryDialog';
import { ReactComponent as DeliveryIcon } from '../_icons/delivery.svg';

const DeliveryOutlinedButton = () => {
  const { open, handleOpen, handleClose } = useDialog();

  return (
    <>
      <StyledButton
        size="medium"
        variant="outlined"
        startIcon={<DeliveryIcon />}
        onClick={handleOpen}
      >
        Доставить результаты курьером
      </StyledButton>
      <DeliveryDialog open={open} onClose={handleClose} />
    </>
  );
};

export default DeliveryOutlinedButton;

import { CircularProgress } from '@material-ui/core';
import React from 'react';
import StyledButton from './StyledButton';

const LoadingButton = ({ disabled, loading, children, ...props }) => {
  return (
    <StyledButton disabled={disabled || loading} {...props}>
      {loading ? <CircularProgress color="inherit" size={20} /> : children}
    </StyledButton>
  );
};

export default LoadingButton;

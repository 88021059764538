import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PrivateRoute from './_helpers/PrivateRoute';
import Spinner from './_helpers/Spinner/Spinner';
import { AppRoutes } from './_helpers/routes';
import Login from './_components/Login/Login';
import './_const/OrderStatusConst';
import PageWrapper from './_components/PageWrapper/PageWrapper';
import ScrollToTop from './_helpers/ScrollToTop';

const MainPage = React.lazy(() => import('./_components/MainPage/MainPage'));
const DynamicResultPage = React.lazy(() => import('./_components/DynamicResult/OrderDynamicResult/OrderDynamicResultPage'));
const IndexDynamicResultPage = React.lazy(() => import('./_components/DynamicResult/IndexDynamicResult/IndexDynamicResultPage'));
const ResultByHashCode = React.lazy(() =>
  import('./_components/ResultByHashCode/ResultByHashCode')
);
const ResultByCodePage = React.lazy(() =>
  import('./_components/ResultByCodePage/ResultByCodePage')
);
const DeliveryPage = React.lazy(() =>
  import('./_components/DeliveryResultPage/DeliveryPage')
);
const SuccessPage = React.lazy(() =>
  import('./_components/DeliveryResultPage/SuccessPage')
);
const FailPage = React.lazy(() =>
  import('./_components/DeliveryResultPage/FailPage')
);

function App() {
  return (
    <>
      <div id="app">
        <ScrollToTop />
        <PageWrapper>
          <Suspense fallback={<Spinner />}>
            <Switch>
              <Route exact path="/" render={() => <Redirect to="/login" />} />
              <Route path={AppRoutes.Login} render={() => <Login />} />
              <Route
                exact
                path={AppRoutes.ResultsByCode}
                render={() => <ResultByCodePage />}
              />
              <PrivateRoute
                exact
                path={AppRoutes.Cabinet}
                component={MainPage}
              />
              <PrivateRoute 
              exact 
              path={AppRoutes.DynamicResult} 
              component={DynamicResultPage} 
              />
              <PrivateRoute 
              exact 
              path={AppRoutes.PersonDynamicResult} 
              component={IndexDynamicResultPage} 
              />
              <Route
                path={AppRoutes.ResultById}
                render={() => <ResultByHashCode />}
              />
              <Route path={AppRoutes.Delivery} component={DeliveryPage} />
              <Route path={AppRoutes.DeliverySuccess} component={SuccessPage} />
              <Route path={AppRoutes.DeliveryFail} component={FailPage} />
            </Switch>
          </Suspense>
        </PageWrapper>
      </div>
    </>
  );
}

export default App;

import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import React from 'react';
import UserIcon from '../MainPage/UserInfo/img/account.svg';
import InfoIcon from './img/info.svg';
import ExitIcon from './img/exit.svg';
import './UserDataDialog.scss';

import userLogout from '../../_helpers/functions/logout';
import { AppRoutes } from '../../_helpers/routes';
import { useHistory } from 'react-router-dom';

const UserDataDialog = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const Logout = () => {
    userLogout();
    history.push(AppRoutes.Login);
  };

  return (
    <>
      <Tooltip title="Мои данные">
        <IconButton
          className="header__user-menu"
          onClick={handleClick}
          aria-controls="menu"
          aria-haspopup="true"
        >
          <img src={UserIcon} width="32px" height="32px" alt="Мои данные" />
        </IconButton>
      </Tooltip>
      <div className="header__flexible">
        <Menu
          id="menu"
          keepMounted
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className="header-menu"
        >
          <Tooltip title="Просмотреть или изменить мои личные данные">
            <MenuItem disabled className="menu__item">
              <ListItemIcon className="menu__icon">
                <img src={InfoIcon} alt="" loading="lazy" />
              </ListItemIcon>
              <span className="menu__label">Мои данные</span>
            </MenuItem>
          </Tooltip>
          <Tooltip title="Выйти из кабинета клиента">
            <MenuItem className="menu__item" onClick={Logout}>
              <ListItemIcon className="menu__icon">
                <img src={ExitIcon} alt="" loading="lazy" />
              </ListItemIcon>
              <span className="menu__label">Выход</span>
            </MenuItem>
          </Tooltip>
        </Menu>
      </div>
    </>
  );
};

export default UserDataDialog;
